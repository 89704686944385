import React from 'react';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/40.jpg';
// import blogd2 from '../../assets/images/categories/scammer.png';
// import blogd3 from '../../assets/images/2022/medical-outreach/3.jpg';
// import blogd4 from '../../assets/images/2022/medical-outreach/4.jpg';
// import blogd5 from '../../assets/images/2022/medical-outreach/5.jpg';
// import blogd6 from '../../assets/images/2022/medical-outreach/6.jpg';
// import blogd7 from '../../assets/images/2022/medical-outreach/7.jpg';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import CourseCatOne from '../../components/CourseCatOne';
import { Link } from 'gatsby';

const NewsDetails = () => {
  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  NOTICE OF CLOSURE OF SALE OF FORMS FOR ADMISSIONS INTO POST
                  BASIC SCHOOLS
                </h2>
                <p className='course-details__tab-text'>
                  To apply for the program, fill the details below with your
                  correct details. <br /> <br />
                  When done, click the <strong>Submit</strong> button and the
                  system will create a login account for you. If successful, you
                  will get a confirmation page with the information that the
                  details of the account have be sent to your email. <br />
                  <br />
                  You can either proceed to make a safe and secure payment
                  through <strong>Remita</strong> by clicking the{' '}
                  <strong>Make Payment</strong> button, selecting your preferred
                  payment option and supplying requisite information/ Or you may
                  close the confirmation page and go to your email to retrieve
                  the details and return to login and following the steps
                  outlined in the email to make your payment.
                  <br />
                  <br />
                  If your payment is successful, you payment status in your
                  login page will be updated to PAID. You will then be able to
                  upload any document due as per program requirements.
                  <br />
                  <br />
                  <strong>
                    REQUIREMENTS for Admission into Post Basic Nursing courses;
                  </strong>
                  <br />
                  <br />
                  <strong>Eligibility:</strong> Registered Nurse
                  <br />
                  {/* <strong>
                            School of Health Information Cut-off (ND):
                          </strong>{' '}
                          150 and above Federal
                          <br /> */}
                  <strong>Experience:</strong> At Least One Year
                  <br />
                  <br />
                  <strong>Date of Examination/Interview</strong>
                  <br />
                  <strong>Date:</strong> Saturday 11th January, 2025
                  <br />
                  <strong>Time:</strong> 8:00am
                  <br />
                  <strong>Venue:</strong>Human Resourses Auditorium, LUTH
                  <br />
                  <br />
                  <strong>Note:</strong> Please come with the Original\Photocopy
                  of your credentials and the printed Bio-Data Form and RRR payment slip only no other item should be
                  brought to the exam center.
                  <br />
                  <br />
                  For more information on other schools kindly contact
                  {/* <Link to='mailto:schoolsadmin@luth.gov.ng'>
                            schoolsadmin@luth.gov.ng
                          </Link> */}
                  <br />
                  <a href='mailto:schadmin@luthschools.edu.ng'>
                    email: schadmin@luthschools.edu.ng
                  </a>{' '}
                  <br />
                  Phone Number : 08023156096
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={1131} width={800} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Lagos State University Teaching Hospital, Idi Araba admission
                  forms, 2023/2024 Applications are hereby invited from suitably
                  qualified candidates for admission into the following Schools
                  of the Lagos University Teaching Hospital, Idi-Araba; 1.
                  School of Health information Management (SHIM)
                  <br />
                  2. Federal School of Biomedical Engineering (FSBE) Ordinary
                  National Diploma (OND) and Higher National Diploma (HND)
                  <br />
                  3. Community Health Officers Training School (CHOTS)
                  <br />
                  4. School of Anaesthetical Technician
                  <br />
                  5. School of Nursing
                  <br />
                  7. School of Medical and Psychiatry Social Work (SMPSW)
                </p>
                
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  METHOD OF APPLICATION
                </span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  a. Visit the Admission Portal https://luth.gov.ng/schools and
                  click on your desired school.
                  <br />
                  b. Complete the account creation Form Ensure you supply
                  correct information and functional email address. An email
                  containing your login details will be sent to you after the
                  account creation. Through the email, you will get information
                  on how to make payment, immediately or on a later date.
                  <br />
                  c. If you desire to make payment immediately, a prompt to
                  "MAKE PAYMENT will be highlighted after the account creation.
                  <br />
                  d. You will be redirected to the Remita website where you can
                  make payment. A Remita Retrieval Reference (RRR) code
                  automatically generated when your account was created will be
                  attached. Please be informed that various means of payment is
                  supported on the platform.
                  <br />
                  e. On successful completion of payment, the applicant should
                  visit https://luth.gov.ng/profile, l with the user ID sent
                  through the email and complete the Application Form for the
                  desired program
                  <br />
                  Note: APPLICANTS MUST PRINT OUT THEIR COMPLETED ONLINE
                  APPLICATION BIO-DATA WHICH IS SENT TO THEIR EMAIL ON
                  COMPLETION OF APPLICATION. SHORT LISTED APPLICANTS WILL BE
                  REQUIRED TO PRESENT THIS DURING THE ENTRANCE EXAMINATION
                  <br />
                  <br />
                  Closing Date: Six (6) weeks from the date of publication for
                  all Schools except School of Health information Management.
                  Federal School of Biomedical Engineering and School of Nursing
                  (Ordinary National Diploma Courses) which are under the Joint
                  Admissions and Matriculation Board,
                  <br />
                  <br />
                  For Enquiries: Visit MASTER'S TOUCH ACADEMY (No 1 Felix
                  Crescent, behind NRC building, opposite CDC Hall, old Akute
                  Road, iju Obawole)- 07062767341 07068564651
                </p> */}
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div> */}
                <CourseCatOne />
                <span style={{ fontSize: 14, fontStyle: 'italic' }}></span>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Any enquiry on LUTH Schools should on LUTH official Website
                  (luth.gov.ng) or visit the concerned School in LUTH.
                </p>
                {/* <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div> */}
                {/* <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Fig 1: Residents at the department before the program
                  <br />
                  Fig 2: Dr Roberts and Dr Balogun Consultants at Dept of Comm
                  Health at the Outreach.
                </span> */}
                {/* <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Residents dispensing medications in advance for the outreach
                </span> */}
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'>LUTH Management</a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='BEWARE OF FRAUDSTERS   | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
