import React, { useEffect, useContext, useState } from 'react';
import { FirebaseContext } from './Firebase';
import { navigate, Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import Ana from './Forms/Ana';
import CommHealth from './Forms/CommHealth';
import Midwifery from './Forms/Midwifery';
import BioMed from './Forms/BioMed';
import PostBasicNursing from './Forms/PostBasicNursing';
import Psy from './Forms/Psy';
import HIM from './Forms/HIM';
import Nursing from './Forms/Nusing';
import UpdatePassword from './UpdatePassword';
import { Skeleton, ThemeIcon } from '@mantine/core';
import { Checks } from 'tabler-icons-react';

const Profile = () => {
  const { firebase, user, loading } = useContext(FirebaseContext);
  const [localState, setLocal] = useState({});
  const [formToFill, setFormToFill] = useState(null);
  const [submitErr, setSubmitErr] = useState(true);
  const [skloading, setskloading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [paymentMode, setPaymentMode] = useState(null);
  const [bioMode, setBioMode] = useState(null);
  const [passwordMode, setPasswordMode] = useState(false);
  const [pendingDetails, setPendingDetails] = useState({});
  const [files, setFiles] = useState([]);
  let docURLs = [];

  useEffect(() => {
    setTimeout(() => {
      const test = localStorage.getItem('authUser');
      if (!test) {
        navigate('/login');
      }
    }, 5000);
  }, []);

  useEffect(() => {
    const local = localStorage.getItem('local');
    const parsed = JSON.parse(local);
    setLocal({ ...parsed });

    if (parsed) {
      if (
        parsed.course === 'Biomedical Engineering (HND)' ||
        parsed.course === 'Biomedical Engineering (ND)'
      ) {
        setFormToFill(<BioMed />);
      } else if (parsed.course === 'General Nursing') {
        setFormToFill(<Nursing />);
      } else if (parsed.course === 'Medical And Psychiatric Social Work') {
        setFormToFill(<Psy />);
      } else if (parsed.course === 'Anaesthetic Technicians') {
        setFormToFill(<Ana />);
      } else if (parsed.course === 'School of Community Health') {
        setFormToFill(<CommHealth />);
      } else if (parsed.course === 'Midwifery') {
        setFormToFill(<Midwifery />);
      } else if (
        parsed.course === 'Health Information Management (ND)' ||
        parsed.course === 'Health Information Management (HND)'
      ) {
        setFormToFill(<HIM />);
      } else if (
        parsed.course === 'Perioperative Program' ||
        parsed.course === 'Accident & Emergency Program' ||
        parsed.course === 'Ophthalmic Program' ||
        parsed.course === 'Paediatric Program'
      ) {
        setFormToFill(<PostBasicNursing />);
      }
    }
  }, []);

  // useEffect(() => {
  //   if (
  //     local.course === 'Biomedical Engineering (HND)' ||
  //     local.course === 'Biomedical Engineering (ND)'
  //   ) {
  //     setFormToFill(<BioMed />);
  //   } else if (local.course === 'General Nursing') {
  //     setFormToFill(<Nursing />);
  //   } else if (local.course === 'Medical And Psychiatric Social Work') {
  //     setFormToFill(<Psy />);
  //   } else if (local.course === 'Anaesthetic Technicians') {
  //     setFormToFill(<Ana />);
  //   } else if (local.course === 'Community Health Officers Program') {
  //     setFormToFill(<CommHealth />);
  //   } else if (local.course === 'Midwifery') {
  //     setFormToFill(<Midwifery />);
  //   } else if (
  //     local.course === 'Health Information Management (ND)' ||
  //     local.course === 'Health Information Management (HND)'
  //   ) {
  //     setFormToFill(<HIM />);
  //   } else if (
  //     local.course === 'Perioperative Program' ||
  //     local.course === 'Accident & Emergency Program' ||
  //     local.course === 'Ophthalmic Program' ||
  //     local.course === 'Paediatric Program'
  //   ) {
  //     setFormToFill(<PostBasicNursing />);
  //   } else if (local.course === null) {
  //     window.location.reload();
  //   } else {
  //     console.log('loca', local.course);
  //   }
  // }, [local]);

  useEffect(() => {
    if (!loading && user !== null) {
      firebase
        .user({ uid: user.uid })
        .then((fetched) => {
          if (fetched.status === 'Not Completed') {
            firebase
              .checkTransStatus({ code: fetched.rrr })
              .then((rrData) => {
                const status = rrData.data?.status;
                console.log('RR status:', status);

                if (status === '00' || status === '01' || status === '998') {
                  firebase
                    .updateStatus({ uid: user.uid })
                    .then(() => {
                      setPaymentMode(false);
                      setskloading(false);
                    })
                    .catch((error) => {
                      console.error('Error updating status:', error);
                      setskloading(false);
                    });
                } else {
                  setPendingDetails({ ...rrData.data });
                  setPaymentMode(true);
                  setskloading(false);
                }
              })
              .catch((error) => {
                console.error('Error checking transaction status:', error);
                setskloading(false);
              });
          } else if (fetched.status === 'Completed') {
            setPaymentMode(false);
            if (fetched.submitted === true) {
              setBioMode(true);
            }
            setskloading(false);
          }
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          setskloading(false);
        });
    }
  }, [user, firebase, loading]);

  const continuePayment = () => {
    if (pendingDetails?.RRR) {
      window.open(
        `https://luthschoolpayment.web.app/?code=${pendingDetails.RRR}`,
        '_self'
      );
    } else {
      console.error('RRR is missing, cannot continue payment.');
    }
  };

  const print = () => {
    const local = localStorage.getItem('local');
    const parsed = JSON.parse(local);
    if (parsed) {
      if (
        parsed.course === 'Biomedical Engineering (HND)' ||
        parsed.course === 'Biomedical Engineering (ND)'
      ) {
        navigate('/bio-data/bio-med');
      } else if (parsed.course === 'General Nursing') {
        navigate('/bio-data/nursing');
      } else if (parsed.course === 'Medical And Psychiatric Social Work') {
        navigate('/bio-data/med-psy');
      } else if (parsed.course === 'Anaesthetic Technicians') {
        navigate('/bio-data/ana');
      } else if (parsed.course === 'School of Community Health') {
        navigate('/bio-data/comm-health');
      } else if (parsed.course === 'Midwifery') {
        navigate('/bio-data/midwifery');
      } else if (
        parsed.course === 'Health Information Management (ND)' ||
        parsed.course === 'Health Information Management (HND)'
      ) {
        navigate('/bio-data/him');
      } else if (
        parsed.course === 'Perioperative Program' ||
        parsed.course === 'Accident & Emergency Program' ||
        parsed.course === 'Ophthalmic Program' ||
        parsed.course === 'Paediatric Program'
      ) {
        navigate('/bio-data/pbn');
      }
    }
  };

  const closeUpdate = (data) => {
    if (data === 'success') {
      setPasswordMode(false);
    }
  };

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <Skeleton visible={skloading}>
                <div className='blog-one__content text-center'>
                  {paymentMode && (
                    <p className='blog-one__text'>
                      Your payment is still pending, kindly complete payment
                      with these details to complete your registration.
                      <br />
                      Remita Retrieval Reference:{' '}
                      <strong>{pendingDetails.RRR}</strong>
                      <br />
                      <strong>Application fee: </strong>20,000.00
                      <br />
                      <strong>Transaction charges: </strong>212.06
                      <br />
                      <strong>Total: </strong>20,212.06
                      <br />
                      <br />
                      <button
                        onClick={continuePayment}
                        className='contact-one__btn thm-btn'
                      >
                        Continue To Payment
                      </button>
                    </p>
                  )}
                  {bioMode && (
                    <p className='blog-one__text' style={{ fontSize: 20 }}>
                      <ThemeIcon color='green' size={50} radius='xl'>
                        <Checks size={50} />
                      </ThemeIcon>
                      <br />
                      Your application has been completed and received.
                      <br />
                      <br />
                      <button
                        onClick={print}
                        className='contact-one__btn thm-btn'
                      >
                        Print Bio-Data Page
                      </button>
                    </p>
                  )}
                  {!paymentMode && !passwordMode && !bioMode && formToFill}
                  {passwordMode && <UpdatePassword func={closeUpdate} />}
                </div>
              </Skeleton>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='sidebar'>
              <div className='sidebar__single sidebar__category'>
                <h3 className='sidebar__title'>Account Management</h3>
                <ul className='sidebar__category-list'>
                  <li className='sidebar__category-list-item'>
                    {!passwordMode ? (
                      <Link to='#' onClick={() => setPasswordMode(true)}>
                        Change Password
                      </Link>
                    ) : (
                      <Link to='#' onClick={() => setPasswordMode(false)}>
                        Application Form
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
